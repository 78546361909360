//关于注册验证的一些请求的相关处理
import axios from "axios";

// const API_PORT = "https://develop-saas.lfhko.codefriend.top";
// const API_PORT = "https://saas.wshopon.com";
// https://saas.myshoprise.net
const API_PORT = "/api/users/register";
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

const getCode = (email) => {
  console.log(email, "email");
  return axios({
    method: "post",
    url:API_PORT+"/send_code",
    data: {
      email: email,
    },
  });
};

const toRegister = (form) => {
  const { email, code, password, accepted } = form;
  return axios({
    method: "post",
    url: API_PORT,
    data: {
      email,
      code,
      password,
      password_confirmation: password,
      accepted,
    },
  });
};

const sendCustomInformation = (form) => {
  let token = sessionStorage.getItem("token");
  console.log(token);
  return axios({
    method: "post",
    url: API_PORT+"/customer_info",
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      ...form,
    },
  });
};

export { getCode, toRegister, sendCustomInformation };
