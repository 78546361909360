// extracted by mini-css-extract-plugin
export var accept = "index-module--accept--EwTUn";
export var code = "index-module--code--275hN";
export var custom_form = "index-module--custom_form--On1GP";
export var email = "index-module--email--6U-Mz";
export var form = "index-module--form--D8THb";
export var left = "index-module--left--Mhg7u";
export var left_inner = "index-module--left_inner--TDX+3";
export var logo = "index-module--logo--x5OY4";
export var main = "index-module--main--3adiP";
export var mobile_show = "index-module--mobile_show--XaBN0";
export var password = "index-module--password--F1F0w";
export var register = "index-module--register---kke0";
export var right = "index-module--right--UOKKa";
export var text = "index-module--text--+g9GG";