import { LoadingOutlined } from '@ant-design/icons';
import emailIcon from "@images/user/email.svg";
import lockIcon from "@images/user/lock.svg";
import Logo from "@images/user/logo.png";
import MobileLogo from "@images/user/mobile_logo.svg";
import phoneBg from "@images/user/phone_bg.png";
import RightLogo from "@images/user/right_logo.png";
import shieldIcon from "@images/user/shield.svg";
import { Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import { graphql, navigate } from "gatsby";
import React, { useState } from "react";
import Helmet from "react-helmet";
import * as styles from "./index.module.scss";

import { getCode, toRegister } from "@src/utils/register.js";


const CustomForm = () => {
  const passwordReg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*\(\)-=_\+\{\}\[\]\|\\:;\"'<>\?,\.\/])[A-Za-z0-9~`!@#$%^&*\(\)-=_\+\{\}\[\]\|\\:;\"'<>\?,\.\/]{6,20}$/;

  const [form] = Form.useForm();

const [restTime, setRestTime] = useState(0);

  const [accept,setAccept]=useState(false)

  const [loading,setLoading]=useState(false);

  const [registerLoading,setRegisterLoading] =useState(false);

  const onFinish = (data) => {
    setRegisterLoading(true)
    toRegister(data)
      .then((res) => {
        console.log(res, "this is res");
        setRegisterLoading(false)
        if (res.status === 201 && res?.data?.data?.access_token) {
          //设置cookieToken
          navigate("/information/");
          let token = res.data.data.access_token;
          sessionStorage.setItem("token", token);
        } else {
          console.log(res, "this is ");
        }
      })
      .catch((e) => {
        setRegisterLoading(false)
        if (e?.response?.data) {
          const data = e.response.data;
          if (data.status_code === 422 && data.errors) {
            const fieldErrorList = [];
            Object.keys(data.errors).forEach((key) => {
              fieldErrorList.push({
                name: key,
                errors: data.errors[key],
              });
            });
            form.setFields(fieldErrorList);
          }
        }
        console.log(e.response.data);
      });
  };

  const onFinishFailed = () => {
    console.log("not onfinish");
  };

  const handleTriggerCode = () => {
    let email = form.getFieldValue("email");
    setLoading(true);
    getCode(email).then((res)=>{
      setTimeout(()=>{
        setLoading(false);
      },1000)
      wait60();
    })
      .catch((e) => {
        if (e?.response?.data) {
          const data = e.response.data;
          setLoading(false)
          if (data.status_code === 422 && data.errors) {
            const fieldErrorList = [];
            setRestTime(0);
            Object.keys(data.errors).forEach((key) => {
              fieldErrorList.push({
                name: key,
                errors: data.errors[key],
              });
            });
            form.setFields(fieldErrorList);
          }
        }
      });
  };

  const handleAcceptChange=({target})=>{
    setAccept(target.checked)
  }

  const wait60 = () => {
    let rest = 59;
    setInterval(() => {
      if (rest) {
        rest--;
        setRestTime(rest);
      }
    }, 1000);
  };

  return (
    <div className={styles.custom_form}>
      <h1>开始免费试用</h1>
      <Form
        name="basic"
        initialValues={{ agreeRules: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className={styles.form}
        form={form}
      >
        <div className={styles.email}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "请输入邮箱" },
              {
                type: "email",
                message: "邮件格式错误",
              },
            ]}
            className="own"
          >
            <Input
              placeholder="邮箱"
              style={{ height: "48px", borderRadius: "4px" }}
              prefix={<img src={emailIcon} style={{marginRight:"8px"}}></img>}
              maxLength={100}
            />
          </Form.Item>
        </div>

        <div className={styles.password}>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "请输入密码" },
              {
                pattern: new RegExp(passwordReg),
                message: "6-20位英文、数字和字符组合",
              },
            ]}
            help="6-20位英文、数字和字符组合"
          >
            <Input.Password
              placeholder="密码"
              style={{ height: "48px", borderRadius: "4px" }}
              prefix={<img src={shieldIcon} style={{marginRight:"8px"}} />}
              maxLength={100}
            />
          </Form.Item>
        </div>
        <div className={styles.code}>
          <Form.Item
            name="code"
            rules={[
              {
                pattern: new RegExp(/^\d{6}$/),
                message: "请输入6位数字验证码",
              },
              {
                required:true,
                message:"请输入6位数字验证码"
              }
            ]}
            getValueFromEvent={(e)=>{
              return e.target.value.replace(/[^0-9]/ig,"");
            }}
            className="own"
          >
            <Input
              placeholder="验证码"
              style={{ height: "48px", borderRadius: "4px" }}
              maxLength={100}
              suffix={
                loading?<Spin indicator={<LoadingOutlined spin/>}/>:
                (!restTime ? (
                  <a onClick={handleTriggerCode}>发送验证</a>
                ) : (
                  <div style={{ color: "#B0B0B0", fontSize: "14px" }}>
                    重新发送({restTime})
                  </div>
                ))
              }
              prefix={<img src={lockIcon} style={{marginRight:"8px"}}/>}

            ></Input>
          </Form.Item>
        </div>
        <div className={styles.accept}>
          <Form.Item
            name="accepted"
            valuePropName="checked"
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       value
            //         ? Promise.resolve()
            //         : Promise.reject(new Error("您必须接受协议")),
            //   },
            // ]}
          >
            <Checkbox checked={accept} onChange={handleAcceptChange}>
              我接受<a href="/agreement">《用户协议》</a>
              <a href="/terms">《服务条款》</a>
              <a href="/privacy">《隐私政策》</a>
            </Checkbox>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: "48px", borderRadius: "4px",border:"none",fontSize:"16px",color:"#fff"  }}
            disabled={!accept}
            loading={registerLoading}
          >
          注册
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const gotoIndex = () => {
  navigate("/");
};

const Register = ({data}) => {

  const backgroundImg=data.BG.childImageSharp.fluid.srcWebp;
  return (
    <div className={styles.register}>
      <Helmet>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=0" />
        <title>用户注册-Shoprise 官网</title>
      </Helmet>
      <Row style={{ height: "100%" }}>
        <Col md={12} xs={0} sm={0}>
          <div
            className={styles.left}
            style={{ background: `url(${backgroundImg})` }}
          >
            <div className={styles.left_inner}>
              <img src={Logo} alt="logo" width={339} onClick={gotoIndex} />
              <div className={styles.text}>
                数十种免费店铺主题模板，
                <br />
                整合智能选品、自动化管理、精准广告归因等工具于一体，
                <br />
                一站式满足店铺管理及推广需求。
                <br />
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <div className={styles.mobile_show}>
            <img src={phoneBg} alt="phoneBg" width={"100%"} height="auto" />
            <img
              src={MobileLogo}
              alt="mobile_logo"
              className={styles.logo}
              onClick={gotoIndex}
            />
          </div>

          <div style={{ textAlign: "right" }} className={styles.right}>
            <img src={RightLogo} alt="logo" onClick={gotoIndex} />
            <div className={styles.main}>
              <CustomForm />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Register;

const SEO = ()=>{
  return <>
  <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=0" />
</>
}

export const Head=()=>{
 return <SEO></SEO>
}

export const pageQuery = graphql`
  query {
   BG:file(relativePath: {eq: "user/bg.png"}) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 960) {
         ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
